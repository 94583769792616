import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

class Employee {
    constructor (params) {
        this.firstName = params?.firstName || null;
        this.lastName = params?.lastName || null;
        this.city = params?.city || null;
        this.address = params?.address || null;
        this.birthDateAndPlace = params?.birthDateAndPlace || null;
        this.nameId1 = params?.nameId1 || null;
        this.nameId2 = params?.nameId2 || null;
        this.email = params?.email || null;
        this.phone = params?.phone || null;
        this.company = params?.company || null;
        this.position = params?.position || null;
        this.location = params?.location || null;
        this.template = params?.template || null;
        this.employeesGroups = params?.employeesGroups || [];
        this.nfcCode = params?.nfcCode || null;
        this.idCard = params?.idCard || null;
        this.remark = params?.remark || null;
        this.viewAllIncidentTemplates = params?.viewAllIncidentTemplates || null;
        this.login = params?.login || null;
        this.password = params?.password || null;
        this.confirmPassword = params?.confirmPassword || null;
        // original data
        this.originalLocation = params?.location;
        this.originalCompany = params?.company;
    }

    #getBaseJSONStructure () {
        const result = {
            data: {
                type: 'employees',
                attributes: {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    address: this.address,
                    birth_date_and_place: this.birthDateAndPlace,
                    name_id_1: this.nameId1,
                    name_id_2: this.nameId2,
                    email: this.email,
                    phone: this.phone,
                    remark: this.remark,
                    nfccode: this.nfcCode,
                    kronos_id: this.idCard,
                    templates_inspections: this.template ? this.template : null,
                    view_all_incident_templates: Boolean(this.viewAllIncidentTemplates)
                },
                relationships: {
                    position: {
                        data: RELATIONSHIPS.CREATE.POSITION(this.position)
                    }
                }
            }
        };

        if (this.location) {
            result.data.relationships.point = {
                data: RELATIONSHIPS.CREATE.LOCATION(this.location)
            };
        }

        if (this.location === undefined && this.originalLocation) {
            result.data.relationships.point = {
                data: RELATIONSHIPS.DISASSOCIATE.LOCATION(this.originalLocation)
            };
        }

        if (this.city) {
            result.data.relationships.city = {
                data: RELATIONSHIPS.CREATE.CITY(this.city)
            };
        }

        if (this.company) {
            result.data.relationships.company = {
                data: RELATIONSHIPS.CREATE.COMPANY(this.company)
            };
        }

        if (this.company === undefined && this.originalCompany) {
            result.data.relationships.company = {
                data: RELATIONSHIPS.DISASSOCIATE.COMPANY(this.originalCompany)
            };
        }

        if (this.employeesGroups && this.employeesGroups.length > 0) {
            result.data.relationships.employee_groups = {
                data: RELATIONSHIPS.CREATE.EMPLOYEE_GROUPS(this.employeesGroups)
            };
        }

        return result;
    }

    getCreateData () {
        return this.#getBaseJSONStructure();
    }

    getUpdateData (employeeId) {
        const data = this.#getBaseJSONStructure();
        data.data.id = employeeId;

        return data;
    }

    getUpdateLoginData () {
        return {
            login: this.login,
            password: this.password,
            password_confirmation: this.confirmPassword
        };
    }

    parseData (payload) {
        const attributes = payload.attributes || payload;
        const relationships = payload.relationships;
        const employeesGroups = relationships.employee_groups?.data?.map(el => el.id);

        this.firstName = attributes.first_name;
        this.lastName = attributes.last_name;
        this.city = relationships.city.data?.id || null;
        this.address = attributes.address;
        this.birthDateAndPlace = attributes.birth_date_and_place;
        this.nameId1 = attributes.name_id_1;
        this.nameId2 = attributes.name_id_2;
        this.email = attributes.email;
        this.phone = attributes.phone;
        this.company = relationships.company?.data?.id || null;
        this.position = relationships.position?.data?.id || null;
        this.location = relationships.point?.data?.id || null;

        this.template = attributes.templates_inspections || null;

        this.employeesGroups = employeesGroups;
        this.nfcCode = attributes.nfccode;
        this.idCard = attributes.kronos_id;
        this.remark = attributes.remark;
        this.viewAllIncidentTemplates = attributes.view_all_incident_templates;
        this.login = attributes.login;

        this.originalLocation = relationships.point?.data?.id || null;
        this.originalCompany = relationships.company?.data?.id || null;
    }

    resetLoginData (login) {
        this.login = login;
        this.password = null;
        this.confirmPassword = null;
    }
}

export default Employee;
