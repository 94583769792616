import { ABILITY_ACTIONS, ABILITY_SUBJECTS } from '@/constants/abilityParams';

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const checkTokenExpiration = (token) => {
    return parseJwt(token).exp < Date.now() / 1000;
};

export const Routes = {
    'REPORTS': 'reports',
    'REPORTS_PLANNING': 'planningReports',
    'REPORTS_INSPECTIONS_INFRINGEMENT': 'inspectionsInfringementReport',
    'REPORTS_GLOBAL_VIOLATIONS': 'globalViolationsReport',
    'REPORTS_INCIDENTS_TYPES': 'incidentsTypesReport',
    'REPORTS_INCIDENTS_QUANTITY': 'incidentsQuantity',
    'REPORTS_CUSTOM': 'customReports',
    'REPORTS_EZT': 'eztReports',
    'REPORTS_SEST_LUV': 'sestLuvReportsComments',
    'INSPECTIONS': 'inspectionsSummary',
    'INCIDENTS': 'incidents',
    'TRACKS': 'tracks',
    'CUSTOMERS_AND_SITES': 'customersAndSites',
    'CUSTOMERS': 'customers',
    'SITES': 'sites',
    'LOCATIONS': 'locations',
    'EMPLOYEES': 'employees',
    'SUBCONTRACTORS': 'subContractors',
    'ELEMENTS': 'elements',
    'SCHEDULING': 'scheduling',
    'CONFIGURATION': 'configuration',
    'CONFIGURATION_AREA': 'areaConfigurations',
    'CONFIGURATION_TEMPLATES': 'templatesConfigurations',
    'CONFIGURATION_POSITIONS': 'positions',
    'CONFIGURATION_INCIDENTS_TYPES': 'incidentsTypes',
    'CONFIGURATION_INCIDENTS_STATUSES': 'incidentsStatusesConfigurations',
    'CONFIGURATION_SUBCONTRACTORS_TYPES': 'subcontractorsTypes',
    'CONFIGURATION_SITES_GROUPS': 'sitesGroupsConfigurations',
    'CONFIGURATION_EMPLOYEES_GROUPS': 'employeesGroupsConfigurations',
    'CONFIGURATION_IMPORTS': 'imports',
    'SETTINGS': 'settings',
    'CONFIG_GLOBAL': 'configGlobal',
    'SYSTEM_LOG': 'systemLog',
    'LOCALES': 'locales',
    'HOLIDAYS': 'holidays'
};

export const hasAccessToRoute = (ability, route) => {
    switch (route) {
    case Routes.REPORTS:
        return newReportsTabHasVisibleChildren(ability) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.REPORT_TAB);
    case Routes.REPORTS_PLANNING:
    case Routes.REPORTS_INSPECTIONS_INFRINGEMENT:
    case Routes.REPORTS_GLOBAL_VIOLATIONS:
    case Routes.REPORTS_INCIDENTS_TYPES:
    case Routes.REPORTS_INCIDENTS_QUANTITY:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.CUSTOM_REPORTS);
    case Routes.INSPECTIONS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INSPECTION) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.INSPECTION_TAB);
    case Routes.INCIDENTS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INCIDENT) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.INCIDENT_TAB);
    case Routes.TRACKS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.TRACKS) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.TRACKS_TAB);
    case Routes.CUSTOMERS_AND_SITES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.CUSTOMER) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.CUSTOMER_TAB);
    case Routes.CUSTOMERS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.CUSTOMER);
    case Routes.SITES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SITE);
    case Routes.LOCATIONS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.LOCATION);
    case Routes.EMPLOYEES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.EMPLOYEE) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.EMPLOYEE_TAB);
    case Routes.SUBCONTRACTORS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SUBCONTRACTORS) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.SUBCONTRACTORS_TAB);
    case Routes.ELEMENTS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.EMPLOYEE) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.EMPLOYEE_TAB);
    case Routes.SCHEDULING:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INSPECTION_SCHEDULE) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.SCHEDULING_TAB);
    case Routes.CONFIGURATION:
        return configurationTabHasVisibleChildren(ability) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.CONFIGURATION_TAB);
    case Routes.CONFIGURATION_AREA:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.CITY);
    case Routes.CONFIGURATION_TEMPLATES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.TEMPLATE);
    case Routes.CONFIGURATION_POSITIONS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.POSITION);
    case Routes.CONFIGURATION_INCIDENTS_TYPES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INCIDENT_TYPES);
    case Routes.CONFIGURATION_INCIDENTS_STATUSES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INCIDENT_STATUS);
    case Routes.CONFIGURATION_SUBCONTRACTORS_TYPES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SUBCONTRACTORS_TYPES);
    case Routes.CONFIGURATION_SITES_GROUPS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SITE_GROUP);
    case Routes.CONFIGURATION_EMPLOYEES_GROUPS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.EMPLOYEE_GROUP);
    case Routes.CONFIGURATION_IMPORTS:
        return canImportData(ability);
    case Routes.SETTINGS:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SYSTEM_SETTINGS) && ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.SETTINGS_TAB);
    case Routes.CONFIG_GLOBAL:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SYSTEM_SETTINGS);
    case Routes.SYSTEM_LOG:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SYSTEM_LOG);
    case Routes.LOCALES:
        return ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.TRANSLATIONS);
    case Routes.HOLIDAYS:
        return ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.SYSTEM_SETTINGS);
    default:
        return false;
    }
};

const newReportsTabHasVisibleChildren = ability =>
    ability.can(ABILITY_ACTIONS.INCORRECT_INSPECTIONS_REPORT, ABILITY_SUBJECTS.REPORTS) ||
    ability.can(ABILITY_ACTIONS.GLOBAL_VIOLATIONS_REPORT, ABILITY_SUBJECTS.REPORTS) ||
    ability.can(ABILITY_ACTIONS.INCIDENTS_TYPES_REPORT, ABILITY_SUBJECTS.REPORTS) ||
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.CUSTOM_REPORTS);

const configurationTabHasVisibleChildren = ability =>
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.CITY) ||
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.TEMPLATE) ||
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.POSITION) ||
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INCIDENT_TYPES) ||
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INCIDENT_STATUS) ||
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.SITE_GROUP) ||
    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.EMPLOYEE_GROUP);

const canImportData = ability =>
    ability.can(ABILITY_ACTIONS.IMPORT_TEMPLATE, ABILITY_SUBJECTS.TEMPLATE) ||
    ability.can(ABILITY_ACTIONS.IMPORT_IMPORT_POINT_XLSX, ABILITY_SUBJECTS.LOCATION) ||
    ability.can(ABILITY_ACTIONS.IMPORT_EMPLOYEES_XLSX, ABILITY_SUBJECTS.EMPLOYEE) ||
    ability.can(ABILITY_ACTIONS.IMPORT_PLACES_XLSX, ABILITY_SUBJECTS.SITE) ||
    ability.can(ABILITY_ACTIONS.IMPORT_CUSTOMERS_XLSX, ABILITY_SUBJECTS.CUSTOMER);
