import { RELATIONSHIPS, INCLUDES } from '@/helpers/jsonapiHelpers';
import { uuid } from 'uuidv4';

class Site {
    constructor (params) {
        this.name = params?.name || '';
        this.cityId = params?.cityId || '';
        this.streetAndNumber = params?.streetAndNumber || '';
        this.employeesWithAccessIds = params?.employeesWithAccessIds || [];
        this.oldEmployeesWithAccessIds = params?.employeesWithAccessIds || [];
        this.internalIdentifier = (params?.internalIdentifier !== undefined) ? params?.internalIdentifier : '';
        this.externalIdentifier = (params?.externalIdentifier !== undefined) ? params?.externalIdentifier : '';
        this.comment = params?.comment || '';
        this.placeGroups = params?.placeGroups || [];
        this.latitude = (params?.latitude !== undefined) ? params?.latitude : null;
        this.longitude = (params?.longitude !== undefined) ? params?.longitude : null;
        this.placeCode = params?.placeCode || '';
        this.zipCode = params?.zipCode || '';
        this.emailsForNotifications = params?.emailsForNotifications || [];
        this.phonesForNotifications = params?.phonesForNotifications || [];
        this.informAboutAll = params?.informAboutAll || false;
        this.skipIncidentNotifications = params?.skipIncidentNotifications || false;
        this.nestedLocations = params?.nestedLocations || [];
        this.file = params?.file || '';
    }

    getSiteAttributes () {
        return {
            attributes: {
                street_and_number: this.streetAndNumber,
                name: this.name,
                latitude: this.latitude,
                longitude: this.longitude,
                employees_with_access_ids: this.employeesWithAccessIds,
                comment: this.comment,
                place_code: this.placeCode,
                external_identifier: this.externalIdentifier,
                internal_identifier: this.internalIdentifier,
                email_notification_employees_ids: this.emailsForNotifications,
                phone_notification_employees_ids: this.phonesForNotifications,
                inform_all_inspections: this.informAboutAll,
                skip_incident_notifications: this.skipIncidentNotifications
            },
            relationships: {
                city_id: this.cityId,
                nestedLocations: this.nestedLocations,
                place_groups: this.placeGroups,
                place_employees: this.employeesWithAccessIds
            },
            dataToModify: {
                employees_with_access_ids_to_delete: this.oldEmployeesWithAccessIds.filter(e => this.employeesWithAccessIds.indexOf(e.toString()) === -1),
                employees_with_access_ids_to_add: this.employeesWithAccessIds.filter(el => this.oldEmployeesWithAccessIds.indexOf(el) === -1)
            }
        };
    }

    parseNestedLocations (nestedLocations, siteId) {
        const result = {
            locationRelationships: {
                data: []
            },
            locationIncludes: []
        };

        nestedLocations.forEach(location => {
            const locationTempId = uuid();
            const { relationships, included } = location.getCreateRelationshipsAndIncludes(siteId, true);

            result.locationRelationships.data.push(RELATIONSHIPS.CREATE.ADD_LOCATION(locationTempId));

            result.locationIncludes.push(INCLUDES.ADD_LOCATION(locationTempId, {
                attributes: location.getLocationAttributes(),
                relationships: relationships
            }));

            result.locationIncludes = [...result.locationIncludes, ...included];
        });

        return result;
    }

    getDataForCreate (customerId, nestedLocations = null) {
        const site = this.getSiteAttributes(nestedLocations);
        const payload = {
            data: {
                type: 'places',
                attributes: site.attributes,
                relationships: {
                    customer: {
                        data: RELATIONSHIPS.CREATE.CUSTOMER(customerId)
                    }
                }
            }
        };

        if (site.relationships.place_employees !== this.oldEmployeesWithAccessIds) {
            if (site.dataToModify.employees_with_access_ids_to_add.length > 0) {
                payload.data.relationships.place_employees = {
                    data: []
                };
                payload.included = [];
                site.dataToModify.employees_with_access_ids_to_add.forEach(employeeId => {
                    const tempId = uuid();
                    payload.included.push(INCLUDES.ADD_PLACE_EMPLOYEE(tempId, employeeId));
                    payload.data.relationships.place_employees.data.push(RELATIONSHIPS.CREATE.PLACE_EMPLOYEE(tempId));
                });
            }
            if (site.dataToModify.employees_with_access_ids_to_delete.length > 0) {
                payload.data.relationships.employees = {
                    data: []
                };
                site.dataToModify.employees_with_access_ids_to_delete.forEach(employeeId => {
                    payload.data.relationships.employees.data.push(RELATIONSHIPS.DISASSOCIATE.EMPLOYEE(employeeId));
                });
            }
        }
        if (site.relationships.city_id) {
            payload.data.relationships.city = {
                data: RELATIONSHIPS.CREATE.CITY(site.relationships.city_id)
            };
        }

        if (site.relationships.place_groups.length > 0) {
            payload.data.relationships.place_groups = {
                data: RELATIONSHIPS.CREATE.SITE_GROUPS(site.relationships.place_groups)
            };
        }

        return payload;
    }

    update (siteId, customerId) {
        const site = this.getDataForCreate(customerId);
        site.data.id = siteId;

        return site;
    }

    getDataForUpdate (siteId, customerId, nestedLocations = null) {
        const site = this.getDataForCreate(customerId, nestedLocations);
        site.data.id = siteId;

        return site;
    }

    getDataForCreateWithNestedLocations (customerId, nestedLocations) {
        const _payload = this.getDataForCreate(customerId, nestedLocations);
        const siteId = uuid();

        const { locationRelationships, locationIncludes } = this.parseNestedLocations(nestedLocations, siteId);

        _payload.data.relationships.points = locationRelationships;

        _payload.included = locationIncludes;

        return _payload;
    }

    getDataForUpdateWithNestedLocations (siteId, customerId, nestedLocations = null) {
        const site = this.getDataForCreateWithNestedLocations(customerId, nestedLocations, siteId);
        site.data.id = siteId;

        return site;
    }

    parseData (payload) {
        this.name = payload.name || '';
        this.cityId = payload.city_id || '';
        this.streetAndNumber = payload.street_and_number || '';
        this.employeesWithAccessIds = payload.employees_with_access_ids || [];
        this.oldEmployeesWithAccessIds = payload.employees_with_access_ids || [];
        this.internalIdentifier = (payload.internal_identifier !== undefined) ? payload.internal_identifier : '';
        this.externalIdentifier = (payload.external_identifier !== undefined) ? payload.external_identifier : '';
        this.comment = payload.comment || '';
        this.placeGroups = payload.place_groups || [];
        this.latitude = (payload.latitude !== undefined) ? payload.latitude : '';
        this.longitude = (payload.longitude !== undefined) ? payload.longitude : '';
        this.placeCode = payload.place_code || '';
        this.zipCode = payload.zip_code || '';
        this.emailsForNotifications = payload.email_notification_employees_ids?.map(el => el.toString()) || [];
        this.phonesForNotifications = payload.phone_notification_employees_ids?.map(el => el.toString()) || [];
        this.informAboutAll = payload.inform_all_inspections || false;
        this.skipIncidentNotifications = payload.skip_incident_notifications || false;
        this.file = payload.file || '';
    }
}

export default Site;
